.app {
  text-align: center; 
}

.body-app {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-playstore-logo {
    height: 80px;
}

.app-create {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  width: 180px;
  height: 35px;
  border-radius: 8px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
}

.app-box {
  margin: 10px;
  background-color: rgba(39, 39, 39, 0.6);
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.app-info {
  text-align: left;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 1.8;
}

/* 
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
